import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';

import Topbar from './components/Topbar';
import Footer from './components/Footer';
import Features from './components/Features';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Topbar />
      <Features />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
