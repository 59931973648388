import { ReactElement } from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react';
import { FcDonate, FcInTransit } from 'react-icons/fc';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { GiFactoryArm, GiCargoCrane } from 'react-icons/gi';

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Box p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={AiOutlineCloudServer} color="orange" w={10} h={10} />}
          title={'E-Commerce'}
          text={
            'We provide a market place for buying and selling automotive spare parts and accessories'
          }
        />
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'Logistics & Transportation'}
          text={
            'Pick, Pack and delivery services. Return management, quality control, nation-wide store distribution'
          }
        />
        <Feature
          icon={<Icon as={GiCargoCrane} color="orange" w={10} h={10} />}
          title={'International Exports & Delivery'}
          text={
            'International import and export services for automative spare parts, food-stuffs and cosmetics'
          }
        />
      </SimpleGrid>
    </Box>
  );
}
